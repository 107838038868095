<template>
	<el-dialog class="dialog" title="编辑" :visible.sync="visiable">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :model="formData" label-width="180px">
				<el-row>
				  <el-col :span="20">
				    <el-form-item label="一级报警:" prop="name">
				      <el-input v-model="formData.levelOneThreshold"></el-input>
				    </el-form-item>
				  </el-col>
				</el-row>
				<el-row>
				  <el-col :span="20">
				    <el-form-item label="二级报警:" prop="name">
				      <el-input v-model="formData.levelTwoThreshold"></el-input>
				    </el-form-item>
				  </el-col>
				</el-row>
				<el-row>
				  <el-col :span="20">
				    <el-form-item label="三级报警:" prop="name">
				      <el-input v-model="formData.levelThreeThreshold"></el-input>
				    </el-form-item>
				  </el-col>
				</el-row>
			</el-form>
		</div>
		<div slot="footer" class="dialog-footer">
			<el-button size="medium" @click="close()">关闭</el-button>
			<el-button type="primary" size="medium" @click="submit()">提交</el-button>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import smCard from "@c/smCard.vue"
	import configApi from '@config/configApi'
	export default {
		mixins: [dialogMixin],
		components: {
			smCard,
		},
		data() {
			return {
				loading: false,
				formData: {},

			};
		},
		props: {dialogObj:Object},
		watch: {},
		methods: {
			//打开弹窗
			open() {
				this.formData = this.dialogObj.row;
			},
			
			submit() {
				// this.loading = true;
				this.$put("/backend-api/eqp/set/unit/meas/admin/updateEqpSetUnitData", this.formData).then((res) => {
					if (res.code == 1000) {
						this.loading = false;
						this.close();
						this.$message.success(res.msg);
						//刷新表格
						this.$parent.list();

					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//关闭弹窗
			close() {
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss' scoped>
	.dialog {
		::v-deep .cascader {
			display: block;
		}
	}
	::v-deep input::-webkit-outer-spin-button,
	::v-deep input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
	::v-deep input[type='number'] {
		-moz-appearance: textfield;
	}

</style>
