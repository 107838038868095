<template>
	<div class="flex" style="flex-direction: column; height: 100%">
		<sm-card title="筛选查询" noHeader icon="el-icon-search" style="height: 80px">
			<el-form slot="body" class="flex" inline ref="form" :model="searchParm" label-width="120px">
				<el-row class="flex-1">
					<el-col :span="6">
						<el-form-item label="查看企业：">
							<el-select v-model="searchParm.ent_id" clearable :popper-append-to-body="false"
								placeholder="请选择">
								<el-option v-for="item in ents" :key="item.abbr" :label="item.abbr"
									:value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<div>
					<el-button type="primary" @click="list()">查询</el-button>
					<el-button plain @click="clearSearch()">重置</el-button>
				</div>
			</el-form>
		</sm-card>
		<div class="tableModule">
			<div class="title">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span>数据列表</span>
				</div>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table ref="usertable" :data="tableData.records" border :height="tableHeight"
							:header-cell-style="{ 'background-color': '#f9fafc' }" :cell-style="{ padding: 0 }"
							style="margin-bottom: 20px">
							<el-table-column prop="entName" align="center" label="企业名称"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="setUnitName" align="center" label="单体名称"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="id" align="center" label="测点ID"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="name" align="center" label="测点名称"
								show-overflow-tooltip></el-table-column>

							<el-table-column prop="levelOneThreshold" align="center" label="一级报警"
								show-overflow-tooltip></el-table-column>

							<el-table-column prop="levelTwoThreshold" align="center" label="二级报警"
								show-overflow-tooltip></el-table-column>

							<el-table-column prop="levelThreeThreshold" align="center" label="三级报警"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="sta_id" align="center" label="采集站ID"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="sta_sen_id" align="center" label="传感器ID"
								show-overflow-tooltip></el-table-column>

							<el-table-column fixed="right" align="center" label="操作" width="230">
								<template slot-scope="scope">
									<el-button type="text" @click="edit('编辑',scope.row)">编辑</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="pagination">
					<el-pagination style="float: right" @current-change="handleCurrentChange"
						:current-page.sync="searchParm.currentPageNo" :page-size="searchParm.pageSize"
						layout="total, prev, pager, next,jumper" :total="tableData.total"></el-pagination>
				</div>
			</div>
		</div>
		<edit :isShow.sync="showEditDialog" :dialogObj="editObj"></edit>
	</div>
</template>
<script>
	import smCard from '@c/smCard.vue';
	import edit from './edit.vue';
	export default {
		components: {
			smCard,
			edit
		},
		data() {
			return {
				tableHeight: 0,
				tableData: {
					records: []
				},
				searchParm: {
					pageSize: 20,
					currentPageNo: 1
				},
				showEditDialog: false,
				editObj: {},
				ents: []
			};
		},
		created() {
			this.getEnts();
		},
		methods: {
			//自适应表格高度
			calHeight() {
				this.$nextTick(() => {
					const rect = this.$refs.tableContainer.getBoundingClientRect();
					this.tableHeight = rect.height;
				});
			},
			//获取企业列表
			getEnts() {
				this.$get('/backend-api/sys/ent/list', {
					size: 99
				}).then(res => {
					if (res.code == 1000) {
						this.ents = res.data.records;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//清空搜索
			clearSearch() {
				this.searchParm = {
					pageSize: 20,
					currentPageNo: 1
				};
				this.list();
			},
			//列表数据
			list() {
				this.$get('/backend-api/eqp/set/unit/meas/admin/list', this.searchParm).then(res => {
					if (res.code == 1000) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//打开编辑弹窗
			edit(title, row) {
				this.editObj = {
					title: title,
					row: row,
				};
				this.showEditDialog = true;
			},
			//分页选择页面回调
			handleCurrentChange(val) {
				this.searchParm.current = val;
				this.list();
			},
			//删除
			remove(row) {
				this.$confirm('是否确认删除？', '删除绑定', {
						dangerouslyUseHTMLString: true,
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						this.$post('/backend-api/eqp/set/unit/meas/zhihuan/delete', {
							id: row.id
						}).then(res => {
							if (res.code == 1000) {
								this.$message.success(res.msg);
								//刷新表格
								this.list();
							} else {
								this.$message.error(res.msg);
							}
						});
					})
					.catch(() => {});
			}
		},
		mounted() {
			this.list();
			this.calHeight();
			window.addEventListener('resize', this.calHeight);
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.calHeight);
		}
	};
</script>
<style lang="scss" scoped>
	@import '@style/userCenter.scss';
</style>